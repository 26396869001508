.home-container {
  flex: 1;
  background: #fff;
  position: relative;
  .back-container {
    position: absolute;
    top: 80px;
    left: 50px;
    cursor: pointer;
    img {
      margin-right: 15px;
      margin-top: -5px;
    }
    span {
      font-size: 23px;
      font-weight: 300;
      @media screen and (max-width: 800px) {
        display: none;
      }
      @media screen and (max-width: 350px) {
        left: 15px;
      }
      @media screen and (max-width: 350px) {
        left: 10px;
      }
    }
    @media screen and (max-width: 550px) {
      left: 20px;
    }
    @media screen and (max-width: 414px) {
      top: 40px
    }
    @media screen and (max-width: 330px) {
      top: 35px;
      left: 10px;
    }
  }
  .title {
    margin-top: 60px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 53px;
    color: #000;
    &>div {
      @media screen and (max-width: 520px) {
        display: none;
      }
    }
    img {
      @media screen and (max-width: 500px) {
        width: 350px;
      }
      @media screen and (max-width: 450px) {
        width: 300px;
      }
      @media screen and (max-width: 400px) {
        width: 250px;
      }
    }
    @media screen and (max-width: 414px) {
      margin-top: 25px;
      text-align: center;
      font-weight: 700;
      line-height: 40px;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 25px;
  }
}
