html { overflow-x: hidden; }
.generator-container {
  // width: 60%;
  // margin: auto;
  // background: #ffffff;
  // box-shadow: 0 -10px 16px -17px black;
  // margin-top: 30px;
  // margin-bottom: 130px;
  // border-radius: 10px;
  &.dark {
    background: #5c5b5b;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
    ::-webkit-input-placeholder {
      font-style: italic;
      color: #5e5e5e;
    }
    .generator-content {
      .keyword-form {
        background: transparent;
        color: white;
        input {
          background: none;
          color: white;
        }
      }

      .styles-container {
        color: black;
        .style-items > div {
          background: transparent;
          border: 1px solid #ffffff;
          color: white;
          &.active {
            color: #5c5b5b;
            background: #fff;
          }
          &.locked {
            svg {
              background-color: transparent;
            }
          }
        }
      }
    }
    .tool-box {
      border: 1px solid #ced4da;
      border-top: none;
      .tool-btn.save-button {
        display: none;
        background: white;
        color: #1e1e1e;
      }
    }
    .tag-container {
      background: transparent;
    }
    .generated-images-viewer {
      border: 1px solid #ced4da;
      border-bottom: none;
      .time-counter {
        color: white;
      }

      .image-generate-container {
        .tag-container {
          background: transparent;
          input {
            background-color: transparent;
            color: white;
          }

          .tag-button {
            background: #ffffff;
            svg {
              color: #5c5b5b;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 390px) {
    width: auto;
    margin: 0px 30px 36px 30px;
  }

  @media screen and (max-width: 1919px) and (min-width: 391px) {
    width: auto;
    margin-left: 30px;
    margin-right: 30px;
  }

  .generator-content {
    padding: 20px 0 0;
    // padding: 48px 44px 0px 44px;
    // @media screen and (max-width: 390px) {
    //   padding: 28px 25px 0px 25px;
    // }

    // @media screen and (max-width: 1919px) and (min-width: 391px) {
    //   padding-left: calc(25px + (48 / 28) * 1vw);
    //   padding-right: calc(25px + (48 / 28) * 1vw);
    // }
    @media screen and (max-width: 414px) {
      padding: 25px 0 0;
    }

  }
}

.keyword-form {
  align-items: center;
  background: #fff;
  border-radius: 30px;
  color: #444;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  height: 50px;
  justify-content: space-between;
  line-height: 30px;
  padding: 0;
  padding-left: 15px !important;
  padding-right: 0 !important;
  border: 1px solid #ced4da;
  margin: 30px 0;
  &.disabled {
    background: lightgray;
    input, svg,
    .generate-button {
      cursor: not-allowed !important;
    }
    .generate-button {
      background: #16a34a !important;
    }
  }
  ::-webkit-input-placeholder {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #8b8b8b;

    @media screen and (max-width: 375px) {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 768px) {
    padding-left: 15px;
    height: 50px;
    background: white;
  }
  @media screen and (max-width: 520px) {
    padding-right: 15px !important;
  }
  input {
    background: none;
    border: none;
    height: 100%;
    width: 90%;
    padding-top: 14px;
    padding-bottom: 14px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    @media screen and (max-width: 500px) {
      touch-action: manipulation;
      padding: 0px 5px;
    }
    @media screen and (max-width: 375px) and (min-width: 320px) {
      font-size: 14px;
      line-height: 20px;
    }
    &:focus-visible {
      outline: none;
    }
  }

  .utils-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg.xMark {
      padding-right: 15px;
    }
    .faShuffle {
      margin-right: 14px;
      margin-left: 14px;

      @media screen and (max-width: 375px) {
        margin-left: 8px;
        margin-right: 8px;
      }
    }

    .xMark:hover {
      cursor: pointer;
    }
  }

  .generate-button {
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 50px;
    padding: 0 30px;
    background: #16a34a;
    cursor: pointer;

    @media screen and (max-width: 520px) {
      height: 30px;
      width: 30px;
      justify-content: center;
      padding-left: 0px;
      padding-right: 0px;
      svg {
        margin-right: 0;
      }

      p {
        display: none;
      }
    }

    svg {
      margin-right: 14px;
      color: white;
      @media screen and (max-width: 390px) {
        margin-right: 0;
        width: 40px;
      }
      @media screen and (max-width: 1919px) and (min-width: 391px) {
        margin-right: 0;
      }
    }
    p {
      color: #faf9f9;
      font-size: 15px;
      font-weight: 700;
      margin: 10px;
    }

    &:hover {
      background: #22c55e;
    }
  }
}

.styles-container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin: 30px 0 40px;
  @media screen and (max-width: 768px) {
    margin-bottom: 33px;
  }
  @media screen and (max-width: 414px) {
    margin-bottom: 15px;
    padding-left: 0 !important;
    display: block !important;
  }

  .angleDown {
    color: #000;
    position: absolute;
    right: 0;
    top: 40px;
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
      top: 10px;
    }
    @media screen and (max-width: 414px) {
      top: 35px;
    }
  }
  .styles-container-title {
    font-size: 12px;
    font-weight: 700;
    width: 100px;
    color: #000;
    @media screen and (max-width: 768px) {
      text-align: center;
      margin-right: 5px;
      font-size: 12px;
      margin-bottom: 11px;
    }
    @media screen and (max-width: 414px) {
      margin: 0 auto 10px;
    }
  }

  .style-items {
    display: flex;
    justify-self: flex-start;
    align-items: center;
    @media screen and (min-width: 749px) {
      flex: 1;
    }
    @media screen and (max-width: 768px) {
      height: 40px;
      overflow: hidden;
      width: 100%;
      flex-wrap: wrap;
      &.open {
        height: auto;
      }
    }
    @media screen and (max-width: 414px) {
      height: 40px; /* add a fixed height to prevent wrapping */
      overflow: hidden;
      width: 100%;
      align-items: center;
      display: flex;
      justify-self: flex-start;
      flex-wrap: wrap;
      &.open {
        height: 130px;
      }
    }

  }

  .style-items > div {
    border-radius: 5px;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-right: 12px;
    padding: 8px 0;
    text-align: center;
    text-transform: capitalize;
    width: 30%;
    background-color: rgba(51, 183, 69, 0);
    border: 2px solid #9d9d9d;
    box-shadow: 0 12px 26px -8px black;
    cursor: pointer;
    @media screen and (min-width: 391px) and (max-width: 768px) {
      width: 28%;
      margin-bottom: 10px;
    }

    @media screen and (min-width: 600px) and (max-width: 768px) {
      width: 30%;
      margin-bottom: 10px;
    }

    @media screen and (max-width: 768px) {
      margin-right: 8px;
      margin-bottom: 8px;
      box-shadow: 0 7px 7px -7px #000;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: #16a34a;
      border: 2px solid #16a34a;
      color: #ffffff;
    }

    &.locked {
      position: relative;
      background-color: rgb(150, 149, 149);
      color: #fff;
      border: none;
      svg {
        position: absolute;
        right: 6px;
        top: 5px;
        padding: 5px;
        border-radius: 10px;
        background-color: #384660;
      }
    }
  }
}

.images-container {
  // padding: 0 44px 50px 44px;
  // @media screen and (max-width: 390px) {
  //   padding: 0 25px 35px 25px;
  // }

  // @media screen and (max-width: 1919px) and (min-width: 391px) {
  //   padding-right: calc(25px + (44 / 22) * 1vw);
  //   padding-left: calc(25px + (44 / 22) * 1vw);
  // }
  margin-bottom: 20px;
  .before-generate {
    height: 318px;
    line-height: 318px;
    background: #ffffff;
    border: 1px dashed #bab2b2;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    color: #837c7c;
    @media screen and (max-width: 353px) {
      font-size: calc(15px - (353 / 318) * 1vw);
    }
  }
}

.tool-box {
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-top: none;
  height: 80px;
}

.loading-animation {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0 auto;
}
.generated-images-viewer .c {
    margin: 50px auto;
    min-height: 200px;
    width:40px;
    height:40px;
}
.generated-images-viewer .image_error {

}