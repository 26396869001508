.thumbnail {
  overflow: hidden;
  &.blur {
    img {
      filter:blur(20px)
    }
  }
}
.thumbnails {
  display: none;
  .lguFiu > button {
    height: 17px;
    width: 17px;
    line-height: 17px;
    min-width: 17px;
    font-size: 1rem;
  }

  .biYdFZ {
    margin: 0 2px;
  }

  .jtKTCe {
    display: none;
  }
}

.thumbnail-item {
  position: relative;
  padding: 10px;
  overflow: hidden;
  img {
    &.blur {
      cursor: pointer;
      filter: blur(20px);
    }
  }
  svg {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    padding: 8px;
    right: 8px;
    top: 8px;
    @media screen and (max-width: 500px) {
      height: 0.2em !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .thumbnails {
    display: block;
  }
  .thumbnail-item {
      // height: 7vh !important;
      // width: 12vw !important;
      img {
        border-radius: 5px;
        width: 55px;
        height: 55px;
        box-shadow: 0 8px 9px -8px black;
        @media screen and (max-width: 350px) {
          width: 40px;
          height: 40px;
        }
      }
  }
  .rec-arrow {
    font-size: 1em !important;
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    line-height: 20px !important;
  }
  .thumbnail-item svg {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 10px;
    position: absolute;
    top: 30%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
}

@media screen and (max-width: 414px) {
  .thumbnail-item {
    cursor: pointer;
    input {
      position: absolute;
      right: 17px;
      z-index: 999;
      bottom: 17px;
    }
  }
}

.mobiledisplay {
  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    width: 100%;
    height: 100%;
    margin: 0;
    font: inherit;
    color: blue;
    border: 0.15em solid blue;
    border-radius: 10px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    bottom: 0px;
    right: 0px;
    left: 6px;
    top: 12px;
    z-index: 2;
  }
  // Learn about this technique:
  // @link https://moderncss.dev/pure-css-custom-checkbox-style/

  :root {
    --thumbnail-item-color: rebeccapurple;
    --thumbnail-item-disabled: #959495;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  // body {
  //   margin: 0;
  // }

  // form {
  //   display: grid;
  //   place-content: center;
  //   min-height: 100vh;
  // }

  .thumbnail-item {
    grid-gap: 0;
    display: grid;
    font-family: system-ui,sans-serif;
    font-size: 3.2rem;
    font-weight: 700;
    gap: 0;
    grid-template-columns: 1em auto;
    line-height: 1;
    outline: none;
    @media screen and (max-width: 768px) {
      grid-template-columns: 2px 55px;
    }
  @media screen and (max-width: 414px) {
    grid-template-columns: 1em auto;
  }
  }

  .thumbnail-item.subscribed + .thumbnail-item.subscribed,
  .thumbnail-item.guest + .thumbnail-item.guest {
    margin-top: 1em;
  }

  .thumbnail-item--disabled {
    color: var(--thumbnail-item-disabled);
    cursor: not-allowed;
  }

  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    left: 5px;
    height: 1.15em;
    border: none;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    @media (max-width: 350px) {
      height: 50px;
      width: 50px;
    }
  }

  .thumbnail-item.subscribed input[type="checkbox"]::before,
  .thumbnail-item.guest input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--thumbnail-item-color);
    /* Windows High Contrast Mode */
    background-color: #f19a18;
  }

  .thumbnail-item.subscribed input[type="checkbox"]:checked::before,
  .thumbnail-item.guest input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  // input[type="checkbox"]:focus {
  //   outline: max(2px, 0.15em) solid currentColor;
  //   outline-offset: max(2px, 0.15em);
  // }

  // input[type="checkbox"]:disabled {
  //   --thumbnail-item-color: var(--thumbnail-item-disabled);

  //   color: var(--thumbnail-item-disabled);
  //   cursor: not-allowed;
  // }
}