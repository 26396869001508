.generated-images-viewer {
  display: flex;
  background: #fff;
  padding: 40px 80px 30px;
  min-height: 400px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ced4da;
  border-bottom: none;
  &.loader {
    border-radius: 10px;
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
  .time-counter {
    display: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #5c5b5b;
    padding-right: 26px;
    width: 20%;
    height: 33px;
    text-align: right;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.image-generate-container {
  width: 60%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.generator-header {
  width: 100%;
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  .main-image {
    width: 100%;
    height: 100%;
    border: 1px solid #000000;
    border-radius: 5px;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 14px;
      margin-right: 0;
    }
  }
  .thumbnails-main-container {
    &:not(.blur) {
      .view-img {
        display: none
      }
    }
    &.blur {
      cursor: pointer;
      img {
        filter: blur(20px);
      }
    }
    .view-img {
      text-decoration: none;
      position: absolute;
      left: calc(50% - 60px);
      top: calc(50% - 16px);
      color: black !important;
      background: #ffffff61;
      padding: 5px 15px;
      border-radius: 5px;
      font-size: 14px;
    }
    overflow: hidden;
    position: relative;
    width: 58%;
    height: 440px;
    .thumbnails-image {
      height: 100%;
      width: 100%;
      background: #c7c7c7;
      border-radius: 5px;
      border: 1px solid #000;
    }
    @media screen and (max-width: 1399px) {
      height: 385px;
    }
    @media screen and (max-width: 1199px) {
      width: 59%;
      height: 325px;
    }
    @media screen and (max-width: 991px) {
      width: 61%;
      height: 245px;
    }
    @media screen and (max-width: 768px) {
      margin: auto;
      width: 300px;
      height: 300px;
    }
    @media screen and (max-width: 400px) {
      height: 250px;
      width: 250px;
    }
    @media screen and (max-width: 350px) {
      height: 220px;
      width: 220px;
    }
  }
}

@keyframes thumb-lds-ellipsis {
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(12px, 0);
  }
}

.thumbnails-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  height: 100%;
  .lds-ellipsis {
    top: calc(50% - 40px) !important;
    left: calc(50% - 40px) !important;
    transform: scale(0.5);
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
  > .thumbnail {
    -webkit-user-drag: none; /* Safari */
    -moz-user-drag: none; /* Firefox */
    -ms-user-drag: none; /* Internet Explorer */
    user-drag: none; /* Standard syntax */
    cursor:pointer;
    width: 40%;
    float: left;
    margin-left: 10px;
    margin-bottom: 24px;
    max-height: 131px;
    position: relative;
    .thumbnails-image {
      min-height: 130px;
      min-width: 130px;
      background: #c7c7c7;
      border-radius: 5px;
      border: 1px solid #000;
      @media screen and (max-width: 1399px) {
        min-height: 112px;
        min-width: 112px;
      }
      @media screen and (max-width: 1199px) {
        min-height: 92px;
        min-width: 92px;
      }
      @media screen and (max-width: 991px) {
        min-height: 65px;
        min-width: 65px;
      }
    }
    @media screen and (max-width: 1399px) {
      max-height: 112px;
    }
    @media screen and (max-width: 1199px) {
      max-height: 92px;
    }
    @media screen and (max-width: 991px) {
      max-height: 65px;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    &:nth-child(5),
    &:nth-child(6) {
      margin-bottom: 0;
    }

    &:nth-child(odd) {
      margin-left: auto;
      margin-right: auto;
    }

    img {
      height: 100%;
      width: 100%;
      border-radius: 5px;
    }
    &.locked {
      position: relative;
      opacity: 0.5;
      background-color: #d9d9d9;
      text-align: center;
      svg {
        align-items: center;
        border-radius: 50px;
        bottom: 95px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        margin: 0 auto;
        overflow: hidden;
        padding: 0;
        position: relative;
        text-align: center;
        padding: 15px;
        background: #fff;
        @media screen and (max-width: 1399px) {
          bottom: 80px;
        }
        @media screen and (max-width: 1199px) {
          bottom: 69px;
        }
        @media screen and (max-width: 991px) {
          bottom: 55px;
        }
      }
    }
    input[type="checkbox"] {
        position: absolute;
        bottom: 5px;
        right: 5px;
        z-index: 2;
    }
  }
}

.tag-container {
  display: none;
  margin-top: 21px;
  height: 40px;
  @media screen and (max-width: 390px) {
    display: none;
  }
  .tag-button {
    width: 56px;
    text-align: center;
    color: #fff;
    background: #5c5b5b;
    line-height: 40px;
    &:hover {
      cursor: pointer;
    }
  }

  input {
    flex: 1;
    background: #faf9f9;
    border: 1px solid #bab2b2;
    text-indent: 18px;

    &:focus-visible {
      outline: none;
    }

    &::-webkit-input-placeholder {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #5c5b5b;
    }
  }
}
