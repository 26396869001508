.toolbox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  width: 60%;
  margin: auto;
  text-align: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .tool-btn {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 40px;
    line-height: 40px;
    padding: 0px 40px;
    font-weight: 700;
    font-size: 15px;
    color: #ffffff;
    @media screen and (max-width: 991px) {
      width: 90%;
    }
    @media screen and (max-width: 768px) {
      padding: 0px 5px;
      width: 100%;
      &:first-child {
        p {
          display: none;
        }
      }
    }

    svg {
      margin-right: 20px;
      &:hover {
        cursor: pointer;
      }
      @media screen and (max-width: 768px) {
        margin-right: 5px;
      }
    }

    &.download-all {
      display: none;
      @media screen and (max-width: 414px) {
        width: 100%;
        justify-content: center;
        p {
          display: none;
        }
      }
    }

    &.save-button {
      display: none;
      background: #000000;
      @media screen and (max-width: 414px) {
        width: 100%;
        justify-content: center;
      }
    }
  }

  &.inactive-subscription {
    justify-content: center;
    .tool-btn {
      &.download-all {
        display: flex;
        background: #6d6d6d;
        cursor: not-allowed;
        svg {
          cursor: not-allowed;
        }
        p {
          display: contents;
          margin: 0;
        }
      }
    }
  }

  &.subscription {
    justify-content: center;
    .tool-btn {
      &.download-all {
        display: flex;
        background: #989898;
        color: #e2e2e2;
        background: green;
        cursor: pointer;
        &:hover {
          background: #037503;
        }
        p {
          display: contents;
          margin: 0;
        }
      }
    }
  }

  &.public {
    justify-content: center;
    .tool-btn {
      &.download-all {
        display: flex;
        background: #6d6d6d;
        cursor: not-allowed;
        p {
          display: contents;
          margin: 0;
        }
      }
    }
  }


}

